/*
 Mixins
 */
@function if-important($important) {
  @return #{if($important, '!important', '')};
}

@mixin text-color($name, $color, $important: false) {
  .text-#{$name} {
    color: $color if-important($important);
  }
}

@mixin bg-color($name, $color, $important: false) {
  .bg-#{$name} {
    background-color: $color if-important($important);
  }
}

@mixin font-size($sizeValue, $weightValue, $important: false) {
  font-size: $sizeValue if-important($important);
  font-weight: $weightValue if-important($important);
  letter-spacing: -0.0025em if-important($important);
}

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: 8px solid rgba(107, 107, 132, 0.3);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.65, 0, .34, 1);
  z-index: -1;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(2.5, 2.5, 1);
  }
}

// Spaces
$spaces: (
  "spacing-xxs": 4px,
  "spacing-xs": 8px,
  "spacing-s": 16px,
  "spacing-m": 24px,
  "spacing-l": 48px,
  "spacing-xl": 96px,
);

/*
 Design style guide
 */

// greens
$green-91: #07D858;
$green-90: #04BE4C;
$green-10: #CCF2DB;
$green-3: #6FCF97;

// blues
$blue-90: #2F80ED;
$blue-80: #15163F;
$blue-70: #EEF3FF;
$blue-60: #434464;
$blue-60-opaque: rgba(21, 22, 63, 0.8);
$blue-10: #D3E2FF;
$blue-5: #1976D2;
$blue-4: #11508e;
$blue-3: #56CCF2;

// Greys
$grey-dark: #666666;
$grey-2: #989899;
$grey-light: #d8d8d8;
$grey-light2: #F4F4F6;
$grey-light3: #e4e4e4;
$grey-light4: #d2d2d2;
$grey-light5: #202125;
$grey-light6: #f6f6f6;
$grey-light7: #DFDFDF;
$grey-light8: #F6F6F6;
$grey-light9: #202125;
$grey-light10: #cccccc;
$grey-light11: #e0e0e0;
$grey-mid: #999999;
$grey-100: #040404;
$grey-94: #585A6D;
$grey-93: #BDBDBD;
$grey-92: #333333;
$grey-91: #E5E5E5;
$grey-90: #62666E;
$grey-82: #D5D6D8;
$grey-81: #EAEAEC;
$grey-80: #95989E;
$grey-70: #D8D8D8;
$grey-60: #EBEBEB;
$grey-50: #F4F4F6;
$grey-40: #F9F9FB;
$grey-30: #A3A3A3;
$grey-20: #6F6F6F;
$grey-10: #828282;
$grey-9: #767C89;
$grey-11: #495057;

// Red
$red-130: #721C24;
$red-100: #C4183C;
$red-90: #F23333;
$red-50: #EB5757;
$red-80: #EB5757;
$red-10: #FECCD9;
$red-9: #F8D7DA;

// purple
$purple-100: #9B51E0;
$purple-90: #9A50E1;
$purple-40: #EEE3FB;
$purple-10: #F7F1FD;
$purple-9: #F6F1FC;

// yellow
$yellow-10: #f47f16;
$yellow-9: #F9A925;
$yellow-8: #FBC02d;
$yellow-7: #FDD836;
$yellow-6: #FFEb3b;
$yellow-5: #FFEE58;

// amber
$amber-4: #FFD54E;

// orange
$orange-2: #FFE0B1;

// black
$black: $grey-100;

// Primary colors
$primary: #256eff;
$secondary: $grey-60;
$success: #00bf4a;
$danger: #fa003f;
$warning: #F2994A;
$white: #FFFFFF;
$info: #17a2b8;
$dark: #15163f;
$light-grey: #f8f9fa;
$dark-grey: #343a40;
$green: $success;

//Hover Colors
$primary-hover: #054CDB;

// Text Colors
@include text-color('primary', $primary, true);
@include text-color('success', $success, true);
@include text-color('danger', $danger, true);
@include text-color('white', $white, true);
@include text-color('info', $info, true);
@include text-color('dark', $dark, true);
@include text-color('light-grey', $light-grey, true);
@include text-color('dark-grey', $dark-grey, true);
@include text-color('grey-100', $grey-100);
@include text-color('grey-90', $grey-90);
@include text-color('grey-82', $grey-82);
@include text-color('grey-81', $grey-81);
@include text-color('grey-80', $grey-80);
@include text-color('grey-70', $grey-70);
@include text-color('grey-60', $grey-60);
@include text-color('grey-50', $grey-50);
@include text-color('grey-40', $grey-40);
@include text-color('grey-30', $grey-30);
@include text-color('grey-20', $grey-20);
@include text-color('grey-10', $grey-10, true);
@include text-color('red-100', $red-100);
@include text-color('red-80', $red-80);
@include text-color('red-130', $red-130);

// Background Colors
@include bg-color('grey-light', $grey-light, true);
@include bg-color('grey-light11', $grey-light11, true);
@include bg-color('grey-93', $grey-93, true);
@include bg-color('grey-91', $grey-91, true);
@include bg-color('grey-90', $grey-90, true);
@include bg-color('grey-80', $grey-80, true);
@include bg-color('grey-70', $grey-70, true);
@include bg-color('grey-60', $grey-60, true);
@include bg-color('grey-50', $grey-50, true);
@include bg-color('grey-40', $grey-40, true);
@include bg-color('grey-9', $grey-9, true);
@include bg-color('red-100', $red-100, true);
@include bg-color('blue-80', $blue-80, true);
@include bg-color('blue-70', $blue-70, true);
@include bg-color('blue-60', $blue-60, true);
@include bg-color('blue-60-opaque', $blue-60-opaque, true);
@include bg-color('blue-10', $blue-10, true);
@include bg-color('blue', $primary, true);
@include bg-color('green-90', $green-10, true);
@include bg-color('green-10', $green-10, true);
@include bg-color('red-90', $red-10, true);
@include bg-color('red-10', $red-10, true);
@include bg-color('grey-80', $grey-80, true);
@include bg-color('grey-70', $grey-70, true);
@include bg-color('primary', $primary, true);
@include bg-color('purple-100', $purple-100, true);
@include bg-color('red-9', $red-9, true);
@include bg-color('yellow-10', $yellow-10, true);
@include bg-color('yellow-9', $yellow-9, true);
@include bg-color('yellow-8', $yellow-8, true);
@include bg-color('yellow-7', $yellow-7, true);
@include bg-color('yellow-6', $yellow-6, true);
@include bg-color('yellow-5', $yellow-5, true);
@include bg-color('amber-4', $amber-4, true);
@include bg-color('orange-2', $orange-2, true);


.bg-primary {
  background: $primary !important;
}

.bg-success {
  background: $success !important;
}

.bg-danger {
  background: $danger !important;
}

.bg-warning {
  background: $warning !important;
}

.bg-dark {
  background: $dark !important;
}

// Font family
$font-family-body: 'Inter', sans-serif;
$font-family-body2: 'Roboto', sans-serif;
$font-family-icons: 'Aloicons', sans-serif;

// Font weights
._100 {
  font-weight: 100 !important;
}

._200 {
  font-weight: 200 !important;
}

._300 {
  font-weight: 300 !important;
}

._400 {
  font-weight: 400 !important;
}

._500 {
  font-weight: 500 !important;
}

._600 {
  font-weight: 600 !important;
}

._700 {
  font-weight: 700 !important;
}

._800 {
  font-weight: 800 !important;
}

// Font sizes
$f-size-h1: 22px;
$f-size-h2: 17px;
$f-size-h3: 16px;
$f-size-h4: 15px;
$f-size-h5: 14px;
$f-size-body-xxxl: 18px;
$f-size-body-xxl: 17px;
$f-size-body-xl: 16px;
$f-size-body-lg: 15px;
$f-size-body: 14px;
$f-size-body-sm: 13px;
$f-size-body-xs: 12px;
$f-size-body-xxs: 11px;
$f-box-title: 28px;

// Texts
h1 {
  @include font-size($f-size-h1, 600);
  @extend .text-grey-100;
}

h2 {
  @include font-size($f-size-h2, 600);
  @extend .text-grey-100;
}

h3 {
  @include font-size($f-size-h3, 600);
  @extend .text-grey-100;
}

h4 {
  @include font-size($f-size-h4, 600);
  @extend .text-grey-100;
}

h5 {
  @include font-size($f-size-h5, 500);
  @extend .text-grey-100;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family-body;
  margin-bottom: 0rem;
}

body {
  @include font-size($f-size-body, 400);
  @extend .text-grey-100;
  font-family: $font-family-body;
}

.text-xxxl {
  @include font-size($f-size-body-xxxl, 400, true);
  @extend .text-grey-100;
}

.text-size-xxxl {
  @include font-size($f-size-body-xxxl, 400);
}

.text-xxl {
  @include font-size($f-size-body-xxl, 400, true);
  @extend .text-grey-100;
}

.text-size-xxl {
  @include font-size($f-size-body-xxl, 400);
}

.text-xl {
  @include font-size($f-size-body-xl, 400, true);
  @extend .text-grey-100;
}

.text-size-xl {
  @include font-size($f-size-body-xl, 400);
}

.text-lg {
  @include font-size($f-size-body-lg, 400, true);
  @extend .text-grey-100;
}

.text-size-lg {
  @include font-size($f-size-body-lg, 400);
}

.text-size-lg-2 {
  @include font-size($f-size-body-lg, 500);
}

.text-rg {
  @include font-size($f-size-body, 400, true);
  @extend .text-grey-100;
}

.text-size-rg {
  @include font-size($f-size-body, 400);
}

.text-sm {
  @include font-size($f-size-body-sm, 500, true);
  @extend .text-grey-100;
}

.text-size-sm {
  @include font-size($f-size-body-sm, 500);
}

.text-xs {
  @include font-size($f-size-body-xs, 400, true);
  @extend .text-grey-100;
}

.text-size-xs {
  @include font-size($f-size-body-xs, 400);
}

.text-xxs {
  @include font-size($f-size-body-xxs, 400, true);
  @extend .text-grey-100;
}

.text-xxs2 {
  @include font-size($f-size-body-xxs, 500, true);
}

.text-size-xxs {
  @include font-size($f-size-body-xxs, 400);
}

/*
 Other styling
 */

// Colors
$orange-light: #FFF9F7;

// System Colors
$blue: $primary;
$orange: #ff9f1c;
$orange2: #F15A22;
$red: $danger;
$turquoise: #00c0cc;
$light-green: #cff8df;
$light-green2: #F2FBF6;
$light-green3: #B2EBC8;
$light-green4: #CCF2DB;
$flesh: #feefe5;
$light-red: #ffe8ee;
$light-red1: #FECCD9;
$light-red2: #FCE7E7;
$light-blue: #e1ebfe;
$light-blue1: #D3E2FF;
$light-blue3: rgba(37, 110, 255, 0.06);
$light-blue4: #EEF3FF;
$light-orange: #fff2df;
$light-orange2: #f2994a;

$yellow-1: #F2C94C;
$purple-2: #BB6BD9;

$f-size-12: 12px;
$f-size-13: 13px;
$f-size-14: 14px;

// Calendar events colors
$upcoming-appointment: #256EFF;
$past-appointment: rgba($upcoming-appointment, 0.6);
$canceled-appointment: $upcoming-appointment;
$missed-appointment: #DC3545;
$upcoming-reminder: #8956F8;
$past-reminder: rgba($upcoming-reminder, 0.6);
$canceled-reminder: $upcoming-reminder;
$completed-event: #00BF4A;

// Wallboard colors
$agent-status-offline: $grey-80;
$agent-status-available: $success;
$agent-status-busy: $orange2;
$agent-status-on-break: $light-orange2;
$agent-status-wrap-up: $purple-2;
$agent-status-on-call: $light-blue1;
$agent-status-ringing: $turquoise;
$agent-status-auto-dial: $grey-93;
$agent-status-sentry: $dark;
$agent-status-all: transparent;

@include bg-color('agent-status-offline', $agent-status-offline, true);
@include bg-color('agent-status-available', $agent-status-available, true);
@include bg-color('agent-status-busy', $agent-status-busy, true);
@include bg-color('agent-status-on-break', $agent-status-on-break, true);
@include bg-color('agent-status-wrap-up', $agent-status-wrap-up, true);
@include bg-color('agent-status-on-call', $agent-status-on-call, true);
@include bg-color('agent-status-ringing', $agent-status-ringing, true);
@include bg-color('agent-status-auto-dial', $agent-status-auto-dial, true);
@include bg-color('agent-status-sentry', $agent-status-sentry, true);
@include bg-color('agent-status-all', $agent-status-all, true);

@include text-color('agent-status-offline', $agent-status-offline);
@include text-color('agent-status-available', $agent-status-available);
@include text-color('agent-status-busy', $agent-status-busy);
@include text-color('agent-status-on-break', $agent-status-on-break);
@include text-color('agent-status-wrap-up', $agent-status-wrap-up);
@include text-color('agent-status-on-call', $agent-status-on-call);
@include text-color('agent-status-ringing', $agent-status-ringing);
@include text-color('agent-status-auto-dial', $agent-status-auto-dial);
@include text-color('agent-status-sentry', $agent-status-sentry);
